import React from 'react';
import pure from 'recompose/pure';
import SvgIcon from '@material-ui/core/SvgIcon';

let Partner: any = (props: any) => (
  <SvgIcon {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15 16">
    <path
      d="M6.72401 9.7H7.31681L7.02041 10.2172L6.72401 9.7ZM6.19732 15.1608L7.02041 11.6728L7.84349 15.1608L9.20381 15.1701L10.5515 9.78936C12.1478 10.1227 13.3408 11.521 13.3408 13.2V14.5C13.3408 14.9396 12.9823 15.3 12.5364 15.3H1.50437C1.05851 15.3 0.7 14.9396 0.7 14.5V13.2C0.7 11.521 1.89299 10.1227 3.48932 9.78936L4.83701 15.1701L6.19732 15.1608ZM10.3321 4C10.3321 5.82086 8.85155 7.3 7.02041 7.3C5.18927 7.3 3.70875 5.82086 3.70875 4C3.70875 2.17914 5.18927 0.7 7.02041 0.7C8.85155 0.7 10.3321 2.17914 10.3321 4Z"
      fill="transparent"
      strokeWidth="1.4"
    />
  </SvgIcon>
);
Partner = pure(Partner);
Partner.displayName = 'Partner';
Partner.muiName = 'SvgIcon';

export default Partner;
