import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import useStyles from '../MenuItem.styles';
import { Props } from './types';
import SubMenu from './SubMenu';

const NestableMenuItem = ({ Icon, name, compact, subMenuItems }: Props) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [anchor, setAnchor] = useState<null | HTMLElement>(null);

  const toggleOpen = (event: React.MouseEvent<any>) => {
    setOpen(!open);
    setAnchor(event.currentTarget);
  };

  // превентим возможность открыть сразу
  // несколько всплывающих подменюшек.
  useEffect(() => {
    setOpen(false);
  }, [compact]);

  return (
    <>
      <ListItem
        className={clsx(classes.menuItem, {
          open
        })}
        button
        key={name}
        onClick={toggleOpen}
      >
        <ListItemIcon className={classes.menuButton}>
          <Icon className={classes.svg} />
        </ListItemIcon>
        {!compact && (
          <ListItemText
            classes={{
              root: classes.menuItemRoot,
              primary: classes.menuItemPrimary
            }}
            primary={name}
          />
        )}
      </ListItem>
      <SubMenu
        compact={compact}
        open={open}
        items={subMenuItems}
        anchorEl={anchor}
        onClose={toggleOpen}
      />
    </>
  );
};

export default NestableMenuItem;
