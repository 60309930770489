import { createStyles, makeStyles } from '@material-ui/core';
import { Theme } from '../../../theme';

export default makeStyles((theme: Theme) =>
  createStyles({
    toggleLink: {
      textAlign: 'left',
      outline: 'none !important'
    },
    expandIcon: {
      color: theme.additionalPalette.accentBlue
    }
  })
);
