import React from 'react';
import List from '@material-ui/core/List';
import { Props } from './types';
import NestableMenuItem from './NestableMenuItem';
import MenuItem from './MenuItem';

const MenuList = ({ compact, items }: Props) => (
  <List component="nav">
    {items &&
      items.map(({ name, Icon, subMenuItems }) =>
        subMenuItems ? (
          <NestableMenuItem
            name={name}
            Icon={Icon}
            compact={compact}
            subMenuItems={subMenuItems}
            key={name}
          />
        ) : (
          <MenuItem name={name} icon={Icon} compact={compact} key={name} />
        )
      )}
  </List>
);

export default MenuList;
