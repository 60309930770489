import React, { useState } from 'react';
import useStyles from './App.styles';
import CssBaseline from '@material-ui/core/CssBaseline';

import AppBar from './components/AppBar';
import SideBar from './components/SideBar';
import BackOfficeUsersPage from './pages/back-office-users';

const App = () => {
  const classes = useStyles();
  const [mobileOpen, setMobileOpen] = useState(false);

  return (
    <div className={classes.root}>
      <CssBaseline />
      <SideBar mobileOpen={mobileOpen} setMobileOpen={setMobileOpen} />
      <div className={classes.contentWrapper}>
        <AppBar mobileOpen={mobileOpen} setMobileOpen={setMobileOpen} />
        <main className={classes.content}>
          <BackOfficeUsersPage />
        </main>
      </div>
    </div>
  );
};

export default App;
