import { createStyles, makeStyles } from '@material-ui/core';
import { Theme } from '../../../theme';

export default makeStyles((theme: Theme) =>
  createStyles({
    box: {
      paddingLeft: 20,
      marginBottom: 10,
      display: "flex",
      alignItems: "center"
    },
    avatar: {
      marginRight: 10,
    },
    name: {
      fontWeight: 500,
      color: theme.additionalPalette.text
    },
    role: {
      fontSize: "0.70rem",
      opacity: 0.6
    }
  })
);
