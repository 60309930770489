import React from 'react';
import Box from '@material-ui/core/Box';
import Avatar from '@material-ui/core/Avatar';
import useStyles from './UserInfo.styles';

interface Props {
  compact: boolean;
}

const USER = {
  name: 'Илья Соловьев',
  role: 'Администратор',
  avatarUrl: 'https://i.pravatar.cc/150?u=a04fbdar252p9'
};

const UserInfo = ({ compact }: Props) => {
  const classes = useStyles();

  return (
    <Box className={classes.box}>
      <Avatar alt={USER.name} src={USER.avatarUrl} className={classes.avatar} />
      <Box hidden={compact}>
        <div className={classes.name}>{USER.name}</div>
        <div className={classes.role}>{USER.role}</div>
      </Box>
    </Box>
  );
};

export default UserInfo;
