import { createStyles, makeStyles } from '@material-ui/core';
import { Theme } from '../../theme';

const drawerWidth = 240;

export default makeStyles((theme: Theme) =>
  createStyles({
    drawerContentWrapper: {
      height: '100%'
    },
    menuButton: {
      marginRight: 36
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap'
    },
    drawerOpen: {
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen
      })
    },
    drawerClose: {
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      }),
      overflowX: 'hidden',
      width: theme.spacing(7) + 1,
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(9) + 1
      }
    },
    drawerPaper: {
      width: drawerWidth
    }
  })
);
