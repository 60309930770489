import { createMuiTheme } from '@material-ui/core/styles';
import { Theme as MUITheme } from '@material-ui/core/styles/createMuiTheme';

const primary = {
  main: '#fff'
};

const secondary = {
  main: '#90a0b7'
};

const additionalPalette = {
  accentBlue: '#109cf1',
  yellow: '#ffb946',
  red: '#f7685b',
  green: '#2ed47a',
  purple: '#885af8',
  text: '#333',
  border: '#e0e0e0'
};

export interface Theme extends MUITheme {
  additionalPalette: {
    accentBlue: string;
    yellow: string;
    red: string;
    green: string;
    purple: string;
    text: string;
    border: string;
  };
}

const ThemeObject = {
  palette: {
    primary,
    secondary,
    background: {
      default: '#f5f6f8'
    }
  },
  additionalPalette,
  mixins: {
    toolbar: {
      height: 64
    }
  }
};

export default createMuiTheme(ThemeObject);
