import React from 'react';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import MUIAppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import useStyles from './AppBar.styles';
import { useTheme } from '@material-ui/core/styles';
import { NavBarProps } from '../../types';

const AppBar = ({ mobileOpen, setMobileOpen }: NavBarProps) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <MUIAppBar className={classes.appBar} position={isMobile ? 'fixed' : 'static'}>
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={() => setMobileOpen(!mobileOpen)}
          className={classes.menuButton}
        >
          <MenuIcon />
        </IconButton>
        <Typography variant="h6" noWrap>
          Lorem ipsum dolor
        </Typography>
      </Toolbar>
    </MUIAppBar>
  );
};

export default AppBar;
