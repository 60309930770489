import React from "react";
import { Props } from './types';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import useStyles from '../MenuItem.styles';

const MenuItem = ({icon: Icon, compact, name}: Props) => {
  const classes = useStyles();

  return (<ListItem
    className={classes.menuItem}
    button
    key={name}
  >
    <ListItemIcon className={classes.menuButton}>
      <Icon className={classes.svg} />
    </ListItemIcon>
    {!compact && (
      <ListItemText
        classes={{
          root: classes.menuItemRoot,
          primary: classes.menuItemPrimary
        }}
        primary={name}
      />
    )}
  </ListItem>)
};

export default MenuItem;
