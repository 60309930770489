import React from "react";
import useStyles from "./SideBarViewToggle.styles";
import Link from "@material-ui/core/Link";
import ArrowLeftIcon from "@material-ui/icons/ArrowLeft";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import Box from "@material-ui/core/Box";

interface Props {
  open: boolean;
  onToggle: () => void;
}

const SideBarViewToggle = ({ open, onToggle }: Props) => {
  const classes = useStyles();

  return (
    <Link
      className={classes.toggleLink}
      component="button"
      color="secondary"
      onClick={onToggle}
    >
      {open ? (
        <Box display="flex" alignItems="center">
          <ArrowLeftIcon fontSize="large" />
          <Box mb="2px">Свернуть / Развернуть</Box>
        </Box>
      ) : (
        <Box display="flex" alignItems="center">
          <ArrowRightIcon fontSize="large" className={classes.expandIcon} />
        </Box>
      )}
    </Link>
  );
};

export default SideBarViewToggle;
