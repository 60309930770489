import React from 'react';
import Collapse from '@material-ui/core/Collapse';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { SubMenuProps } from '../../types';
import useStyles from '../../MenuItem.styles';

const SubMenuCollapse = ({ open, items }: SubMenuProps) => {
  const classes = useStyles();

  if (!items) return null;

  return (
    <Collapse in={open} timeout="auto" unmountOnExit>
      <List component="div" disablePadding>
        {items.map(({ name }) => (
          <ListItem button className={classes.nested} key={name}>
            <ListItemIcon className={classes.menuButton}>
              <ArrowRightIcon className={classes.nestedSvg} />
            </ListItemIcon>
            <ListItemText
              classes={{
                root: classes.menuItemRoot,
                primary: classes.menuItemPrimary
              }}
              primary={name}
            />
          </ListItem>
        ))}
      </List>
    </Collapse>
  );
};

export default SubMenuCollapse;
