export default {
  body: {
    noMatch: 'Извините, по вашему запросу ничего не найдено',
    toolTip: 'Сортировка'
  },
  pagination: {
    next: 'Вперед',
    previous: 'Назад',
    rowsPerPage: 'Строк на страницу:',
    displayRows: 'из'
  },
  toolbar: {
    search: 'Поиск',
    downloadCsv: 'Скачать CSV',
    print: 'Печать',
    viewColumns: 'Видимые колонки',
    filterTable: 'Фильтр'
  },
  filter: {
    all: 'Все',
    title: 'ОТФИЛЬТРОВАТЬ',
    reset: 'СБРОС'
  },
  viewColumns: {
    title: 'Видимые колонки',
    titleAria: 'Показать/скрыть колонки в таблице'
  },
  selectedRows: {
    text: 'запись(ей) выбрано',
    delete: 'Удалить',
    deleteAria: 'Удалить выбранные записи'
  }
};
