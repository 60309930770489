import React from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { Props } from '../SubMenu/types';

const SubMenuPopup = ({ open, anchorEl, items, onClose }: Props) => {
  return items ? (
    <Menu anchorEl={anchorEl} open={open} onClose={onClose}>
      {items.map(({ name }) => (
        <MenuItem key={name}>{name}</MenuItem>
      ))}
    </Menu>
  ) : null;
};

export default SubMenuPopup;
