import React from 'react';
import DataTable from '../../components/DataTable';
import { FormGroup, FormLabel, TextField } from '@material-ui/core';
import faker from 'faker';
faker.locale = 'ru';

const BackOfficeUsersPage = () => {
  const columns = [
    {
      name: 'name',
      label: 'Ф.И.О.',
      options: {
        filter: true,
        sort: true,
        filterType: "textField"
      }
    },
    {
      name: 'position',
      label: 'Должность',
      options: {
        filter: true,
        sort: true,
        filterType: "multiselect"
      }
    },
    {
      name: 'department',
      label: 'Подразделение',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'city',
      label: 'Город',
      options: {
        filter: true,
        sort: true,
        filterType: "checkbox"
      }
    },
  ];

  let data = [
    {
      name: 'Варнава Виктория О.',
      position: 'Эксперт',
      department: 'Департамент эксплуатации',
      city: 'Санкт-Петербург'
    },
    {
      name: 'Ворун Ангелина И.',
      position: 'Эксперт',
      department: 'Департамент эксплуатации',
      city: 'Москва'
    },
    {
      name: 'Лебедев Артемий П.',
      position: 'Эксперт',
      department: 'Департамент эксплуатации',
      city: 'Санкт-Петербург'
    },
    {
      name: 'Осипова Александра Н.',
      position: 'Эксперт',
      department: 'Департамент эксплуатации',
      city: 'Санкт-Петербург'
    },
    {
      name: 'Приходько Анастасия Г.',
      position: 'Эксперт',
      department: 'Департамент эксплуатации',
      city: 'Москва'
    },
    {
      name: 'Соловьёв Илья Н.',
      position: 'Контролёр отдела тех. эксплуатации',
      department: 'Отдел технической эксплуатации',
      city: 'Санкт-Петербург'
    },
    {
      name: 'Мирошин Александр С.',
      position: 'Менеджер отдела',
      department: 'ОП',
      city: 'Санкт-Петербург'
    },
    {
      name: 'Гульц Анастасия С.',
      position: 'Руководитель отдела',
      department: 'ОПВС',
      city: 'Москва'
    },
    {
      name: 'Фролов Дмитрий И.',
      position: `Эксперт по санитарной безопасности`,
      department: 'Департамент строительства',
      city: 'Москва'
    },
    {
      name: 'Сорокина Юлианна А.',
      position: 'Региональный координатор',
      department: 'Департамент эксплуатации',
      city: 'Санкт-Петербург'
    }
  ];

  return (
    <DataTable
      title={'Сотрудники back-office'}
      data={data}
      columns={columns as any}
    />
  );
};

export default BackOfficeUsersPage;
