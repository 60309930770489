import React from 'react';
import pure from 'recompose/pure';
import SvgIcon from '@material-ui/core/SvgIcon';

let LogoMultiline: any = (props: any) => (
  <SvgIcon {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 42 23">
    <path
      d="M9.30147 2.60074C9.44205 2.55388 9.65292 2.4133 9.69977 2.36644C9.60606 2.31958 9.51234 2.24929 9.41862 2.15558C9.3249 2.06186 9.11404 1.6167 8.24715 1.6167C7.3334 1.6167 6.9351 2.24929 6.9351 2.74131C6.9351 2.8116 6.9351 2.88189 6.9351 2.95218C5.7402 3.51449 4.68587 4.14708 3.49097 5.59971H6.56023C6.39622 5.67 6.11507 5.76371 5.92763 5.95115L6.18535 6.81804C6.63051 6.79461 7.07567 6.65403 7.35683 6.53689C7.61455 6.41974 8.7626 5.90429 9.20776 4.45166C9.25461 4.31109 9.41862 3.56134 9.44205 3.4442C9.46548 3.32705 9.53577 3.06933 9.74663 2.90532C9.67634 2.85846 9.51234 2.71788 9.3249 2.62417C9.25461 2.62417 9.25461 2.62417 9.30147 2.60074Z"
      fill="#D24A48"
    />
    <path
      d="M8.36434 2.13208C8.10662 2.13208 7.89575 2.34295 7.89575 2.60067C7.89575 2.85839 8.10662 3.06926 8.36434 3.06926C8.62207 3.06926 8.83293 2.85839 8.83293 2.60067C8.83293 2.34295 8.62207 2.13208 8.36434 2.13208Z"
      fill="#4DA162"
    />
    <path
      d="M7.35693 0H3.06933L1.64014 4.14701H3.86594C3.93622 4.10016 4.05337 4.00644 4.17052 3.91272L5.15456 0.749743L5.83401 2.92868C5.92773 2.88182 6.13859 2.76468 6.25574 2.71782C6.3026 1.85093 6.95862 1.35891 7.80208 1.26519L7.35693 0Z"
      fill="#D24A48"
    />
    <path
      d="M1.07776 5.81055L0 8.92666H2.60067L3.37384 6.46657H1.61663C1.68692 6.25571 1.80407 5.97455 1.94465 5.81055H1.07776Z"
      fill="#D24A48"
    />
    <path
      d="M6.63062 6.77105L7.28664 8.94999H7.96609L7.26321 6.58362C7.0992 6.65391 6.86491 6.72419 6.63062 6.77105Z"
      fill="#4DA162"
    />
    <path
      d="M9.1375 5.08423C8.9032 5.55282 8.45804 6.20884 7.49744 6.67743L8.17689 8.92666H10.4495L9.1375 5.08423Z"
      fill="#D24A48"
    />
    <path
      d="M10.801 2.57732C10.637 2.50703 10.3558 2.41331 9.98096 2.41331C9.86381 2.41331 9.77009 2.41331 9.72323 2.36646C9.65295 2.41331 9.46551 2.53046 9.32493 2.60075C9.25464 2.62418 9.25464 2.64761 9.32493 2.67104C9.51237 2.76476 9.67638 2.8819 9.74666 2.95219C10.0044 2.76476 10.2387 2.92876 10.8244 2.67104C10.8713 2.62418 10.8713 2.60075 10.801 2.57732Z"
      fill="#4DA162"
    />
    <path
      d="M0.820071 4.94363C0.843501 4.94363 0.843501 4.94363 0.843501 4.96706C0.843501 4.99048 0.843501 4.99048 0.820071 4.99048C0.42177 4.99048 0.140617 5.27164 0.117188 5.59965H2.36642C2.20241 5.76366 2.01497 6.02138 1.89783 6.2791H5.31853L6.11513 8.95006H6.79458L6.16199 6.84141L5.90426 5.97452C6.11513 5.78709 6.37285 5.69337 6.53686 5.62308H3.53789C4.70936 4.17045 5.78712 3.53786 6.98202 2.97555C6.95859 2.90526 6.98202 2.83497 6.98202 2.76469C6.98202 2.27267 7.38032 1.64007 8.29407 1.64007C8.59865 1.64007 8.92666 1.71036 9.18439 1.87437C9.04381 1.73379 8.55179 1.45264 8.05977 1.45264C7.02888 1.45264 6.41971 2.10866 6.46657 2.83497C5.81055 3.13956 4.68593 3.72529 3.95962 4.31103H0.117188C0.164046 4.6859 0.4452 4.94363 0.820071 4.94363Z"
      fill="#4DA162"
    />
    <path
      d="M8.34084 2.64754L8.786 2.43667C8.73914 2.31952 8.62199 2.20238 8.50485 2.15552L8.29398 2.60068C8.27055 2.64754 8.29398 2.67097 8.34084 2.64754Z"
      fill="white"
    />
    <path
      d="M41.1187 7.09909C41.1187 8.10656 41.1656 8.92659 41.1656 8.92659H38.9867V6.13849C38.9867 5.20131 38.7055 4.84987 38.1901 4.84987C37.5106 4.84987 37.1592 5.36531 37.1592 6.44307V8.92659H35.0271C35.0974 7.2631 35.0271 4.77958 34.9802 3.25666H36.9014C36.9014 3.25666 36.9483 3.49096 36.9483 3.86583V4.07669C37.206 3.70182 38.0026 3.09266 38.9632 3.13952C39.9238 3.18637 40.6033 3.65496 40.9079 4.26413C41.0719 4.61557 41.1187 5.15445 41.1422 5.55275L41.1187 7.09909Z"
      fill="#D24A48"
    />
    <path
      d="M14.3622 8.22376C14.0342 8.59863 13.2845 9.11408 12.441 9.04379C11.2461 8.95007 10.2855 8.41119 10.2855 6.63056V3.25671H12.4176V6.04482C12.4176 6.982 12.6753 7.33344 13.1908 7.33344C13.8702 7.33344 14.1982 6.72427 14.1982 5.74024V3.25671H16.3069C16.2366 4.92021 16.3069 7.40373 16.3538 8.92664H14.4325C14.3388 8.76264 14.3622 8.22376 14.3622 8.22376Z"
      fill="#D24A48"
    />
    <path
      d="M32.4732 8.22372C32.4732 8.73916 32.52 8.95003 32.52 8.95003H34.371C34.371 8.95003 34.3241 7.98942 34.3241 6.09164C34.3241 6.06821 34.3241 4.89673 34.1835 4.56872C34.0429 4.24071 33.7149 3.46754 32.356 3.18638C30.9971 2.90523 29.5679 3.42068 29.3571 3.58468V4.99045C29.3571 4.99045 30.2942 4.475 31.0205 4.475C31.5594 4.475 32.3326 4.52186 32.3795 5.31846C32.3795 5.31846 32.0983 5.31846 31.6766 5.31846C30.3645 5.31846 28.701 5.57619 28.6542 7.19282C28.6307 8.45801 29.7319 9.04375 30.716 9.02032C30.6925 8.99689 31.8172 9.04375 32.4732 8.22372Z"
      fill="#D24A48"
    />
    <path
      d="M30.6457 7.12258C30.5988 6.32598 32.1452 6.44313 32.3326 6.51342C32.356 7.8489 30.6926 7.98947 30.6457 7.12258Z"
      fill="white"
    />
    <path
      d="M26.077 6.16201C26.077 5.22483 25.749 4.87339 25.3039 4.87339C24.6244 4.87339 24.273 5.3654 24.273 6.46659V8.92668H22.1643V1.59326H24.2964V3.81906C24.6478 3.51448 25.2804 3.09275 26.077 3.11618C27.0376 3.16304 28.2325 3.6082 28.2325 5.64656C28.2325 6.3963 28.2325 8.90325 28.2325 8.90325H26.077V6.16201Z"
      fill="#D24A48"
    />
    <path
      d="M21.5317 5.10768C20.7585 4.49851 18.9076 4.54537 18.9076 6.02143C18.9076 7.66149 20.9225 7.61463 21.6723 7.05232L21.7191 8.66895C20.6414 9.23126 16.7521 9.4187 16.7521 6.04485C16.7521 2.67101 20.5945 2.85845 21.6723 3.49104L21.5317 5.10768Z"
      fill="#D24A48"
    />
    <path
      d="M2 20.3283H2.94417V17.5392L3.64958 16.7361L6.04799 20.3283H7.1658L4.31159 16.1175L6.9596 13.0137H5.78753L3.55191 15.7594C3.36742 15.9981 3.17207 16.2586 2.97673 16.5407H2.94417V13.0137H2V20.3283Z"
      fill="#D24A48"
    />
    <path
      d="M12.1796 17.8756C12.2013 17.7671 12.2121 17.626 12.2121 17.4306C12.2121 16.4648 11.7672 14.9671 10.0416 14.9671C8.51143 14.9671 7.57811 16.2043 7.57811 17.7888C7.57811 19.3733 8.54399 20.4477 10.161 20.4477C10.9967 20.4477 11.5827 20.2632 11.9191 20.1112L11.7455 19.4275C11.3982 19.5795 10.975 19.6988 10.2912 19.6988C9.32537 19.6988 8.51143 19.1671 8.48972 17.8756H12.1796ZM8.50058 17.1919C8.57654 16.5299 8.98894 15.6508 9.96567 15.6508C11.0401 15.6508 11.3005 16.595 11.2897 17.1919H8.50058Z"
      fill="#D24A48"
    />
    <path
      d="M17.725 17.8756C17.7467 17.7671 17.7575 17.626 17.7575 17.4306C17.7575 16.4648 17.3126 14.9671 15.587 14.9671C14.0568 14.9671 13.1235 16.2043 13.1235 17.7888C13.1235 19.3733 14.0894 20.4477 15.7064 20.4477C16.542 20.4477 17.1281 20.2632 17.4645 20.1112L17.2909 19.4275C16.9436 19.5795 16.5203 19.6988 15.8366 19.6988C14.8708 19.6988 14.0568 19.1671 14.0351 17.8756H17.725ZM14.046 17.1919C14.1219 16.5299 14.5343 15.6508 15.5111 15.6508C16.5855 15.6508 16.8459 16.595 16.8351 17.1919H14.046Z"
      fill="#D24A48"
    />
    <path
      d="M19.0487 22.4771H19.9929V19.6229H20.0146C20.3293 20.1438 20.9371 20.4477 21.6316 20.4477C22.8688 20.4477 24.03 19.5143 24.03 17.626C24.03 16.0415 23.075 14.9671 21.7944 14.9671C20.9371 14.9671 20.3076 15.3361 19.9278 15.9764H19.9061L19.8518 15.0757H19.0053C19.027 15.5749 19.0487 16.1175 19.0487 16.7904V22.4771ZM19.9929 17.2787C19.9929 17.1593 20.0254 17.0183 20.058 16.8989C20.2316 16.1826 20.8502 15.7268 21.5014 15.7268C22.5107 15.7268 23.0642 16.6276 23.0642 17.6694C23.0642 18.874 22.4781 19.6988 21.4688 19.6988C20.7851 19.6988 20.2099 19.243 20.0363 18.581C20.0037 18.4616 19.9929 18.3314 19.9929 18.1903V17.2787Z"
      fill="#D24A48"
    />
    <path
      d="M27.766 13.0137V17.3221C27.766 19.6012 28.8947 20.4477 30.3923 20.4477C31.9768 20.4477 33.1597 19.536 33.1597 17.2896V13.0137H32.2047V17.3547C32.2047 18.9609 31.4993 19.6771 30.4249 19.6771C29.4482 19.6771 28.721 18.9826 28.721 17.3547V13.0137H27.766Z"
      fill="#D24A48"
    />
    <path
      d="M34.8794 22.4771H35.8236V19.6229H35.8453C36.16 20.1438 36.7678 20.4477 37.4623 20.4477C38.6995 20.4477 39.8607 19.5143 39.8607 17.626C39.8607 16.0415 38.9057 14.9671 37.6251 14.9671C36.7678 14.9671 36.1383 15.3361 35.7585 15.9764H35.7368L35.6825 15.0757H34.836C34.8577 15.5749 34.8794 16.1175 34.8794 16.7904V22.4771ZM35.8236 17.2787C35.8236 17.1593 35.8561 17.0183 35.8887 16.8989C36.0623 16.1826 36.6809 15.7268 37.3321 15.7268C38.3414 15.7268 38.8948 16.6276 38.8948 17.6694C38.8948 18.874 38.3088 19.6988 37.2995 19.6988C36.6158 19.6988 36.0406 19.243 35.867 18.581C35.8344 18.4616 35.8236 18.3314 35.8236 18.1903V17.2787Z"
      fill="#D24A48"
    />
  </SvgIcon>
);
LogoMultiline = pure(LogoMultiline);
LogoMultiline.displayName = 'LogoMultiline';
LogoMultiline.muiName = 'SvgIcon';

export default LogoMultiline;
