import React from 'react';
import MUIDataTable from 'mui-datatables';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import textLabels from './DataTable.translation';

import { Props } from './types';

const DataTable = ({ options, ...rest }: Props) => {
  const themeObject = {
    palette: {
      primary: {
        main: '#333'
      }
    },
    overrides: {
      MUIDataTableBodyCell: {
        root: {}
      }
    },
    typography: {}
  };

  const getMuiTheme = () => createMuiTheme(themeObject as any);

  options = {
    ...options,
    textLabels,
    selectableRows: 'none'
  };

  return (
    <MuiThemeProvider theme={getMuiTheme()}>
      <MUIDataTable {...rest} options={options} />
    </MuiThemeProvider>
  );
};

export default DataTable;
