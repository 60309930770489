import React from "react";
import SubMenuCollapse from '../SubMenuCollapse';
import SubMenuPopup from '../SubMenuPopup';
import { Props } from './types';

const SubMenu = ({open, compact, items, anchorEl, onClose}: Props) => {
  if (!items) return null;

  return compact ? (
      <SubMenuPopup
        open={open}
        anchorEl={anchorEl}
        onClose={onClose}
        items={items}
      />
    ) : (
      <SubMenuCollapse open={open} items={items} />
    )
};

export default SubMenu;
