import { createStyles, makeStyles } from '@material-ui/core';
import { Theme } from '../../../theme';

export default makeStyles((theme: Theme) =>
  createStyles({
    logo: {
      display: 'flex',
      alignItems: 'center',
      padding: '0 20px',
      borderBottom: `1px solid ${theme.additionalPalette.border}`,
      marginBottom: 20,
      ...theme.mixins.toolbar
    },
    wrapper: {
      display: 'flex',
      alignItems: 'flex-end'
    },
    multilineWrapper: {
      display: 'flex',
      flexWrap: 'wrap',
      '& span': {
        fontSize: '0.55rem',
        marginBottom: 0
      }
    },
    label: {
      fontSize: '0.65rem',
      opacity: 0.4,
      marginBottom: 2
    },
    logoSvg: {
      width: 140,
      height: 24,
      marginRight: 5
    },
    multilineLogoSvg: {
      width: '100%',
      height: 24
    }
  })
);
