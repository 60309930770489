import { createStyles, makeStyles } from '@material-ui/core';
import { Theme } from '../../../theme';

export default makeStyles((theme: Theme) =>
  createStyles({
    menuItem: {
      ...getMenuItemStyles(theme),
      '&:hover svg, &.open, &.open svg': {
        color: theme.additionalPalette.accentBlue,
        stroke: theme.additionalPalette.accentBlue
      }
    },
    menuButton: {
      minWidth: 24
    },
    menuItemRoot: {
      marginTop: 0,
      marginBottom: 0
    },
    menuItemPrimary: {
      fontSize: '0.85rem'
    },
    svg: {
      width: 16,
      height: 16,
      stroke: theme.palette.secondary.main
    },
    nested: {
      ...getMenuItemStyles(theme),
      paddingLeft: theme.spacing(4)
    },
    nestedSvg: {
      width: 16,
      height: 16
    }
  })
);

const getMenuItemStyles = (theme: Theme) => ({
  minHeight: 36,
  transition: '150ms',
  '&:hover': {
    backgroundColor: theme.palette.primary.main,
    color: theme.additionalPalette.accentBlue
  }
});
