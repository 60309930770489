import React, { useState } from 'react';
import clsx from 'clsx';

import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import Box from '@material-ui/core/Box';
import { useTheme } from '@material-ui/core';

import useStyles from './StideBar.styles';
import MenuList from './MenuList';
import SideBarViewToggle from './SideBarViewToggle';
import Logo from './Logo';
import ShopIcon from '../../svg/Shop';
import UserIcon from '../../svg/User';
import PartnerIcon from '../../svg/Partner';
import { NavBarProps } from '../../types';
import UserInfo from './UserInfo';

const MENU_ITEMS = [
  {
    name: 'Пользователи',
    Icon: UserIcon,
    subMenuItems: [
      { name: 'Сотрудники back-office' },
      { name: 'Сотрудники магазина' },
      { name: 'Сотрудники контрагента' }
    ]
  },
  {
    name: 'Контрагенты',
    Icon: PartnerIcon
  },
  {
    name: 'Магазины',
    Icon: ShopIcon
  }
];

const SideBar = ({ mobileOpen, setMobileOpen }: NavBarProps) => {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = useState(true);

  const toggleOpen = () => setOpen(!open);
  const toggleMobileOpen = () => setMobileOpen(!mobileOpen);

  return (
    <>
      {/* MOBILE */}
      <Hidden smUp implementation="css">
        <Drawer
          variant="temporary"
          anchor={theme.direction === 'rtl' ? 'right' : 'left'}
          open={mobileOpen}
          onClose={toggleMobileOpen}
          classes={{
            paper: classes.drawerPaper
          }}
          ModalProps={{
            keepMounted: true // Better open performance on mobile.
          }}
        >
          <Box
            className={classes.drawerContentWrapper}
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
          >
            <Box>
              <Logo multiline={false} />
              <UserInfo compact={!open} />
              <MenuList compact={false} items={MENU_ITEMS} />
            </Box>
          </Box>
        </Drawer>
      </Hidden>
      {/* DESKTOP */}
      <Hidden xsDown implementation="css">
        <Drawer
          id="Drawer"
          variant="permanent"
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open
          })}
          classes={{
            paper: clsx({
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open
            })
          }}
          open={open}
        >
          <Box
            className={classes.drawerContentWrapper}
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
          >
            <Box>
              <Logo multiline={!open} />
              <UserInfo compact={!open} />
              <MenuList compact={!open} items={MENU_ITEMS} />
            </Box>
            <SideBarViewToggle open={open} onToggle={toggleOpen} />
          </Box>
        </Drawer>
      </Hidden>
    </>
  );
};

export default SideBar;
