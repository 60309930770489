import { createStyles, makeStyles } from '@material-ui/core';
import { Theme } from './theme';

const appBarHeight = 60;

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex'
    },
    contentWrapper: {
      width: '100%'
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
      paddingTop: appBarHeight + 20,
      [theme.breakpoints.up('sm')]: {
        paddingTop: theme.spacing(3)
      }
    }
  })
);
