import React from 'react';
import Box from '@material-ui/core/Box';
import useStyles from './Logo.styles';
import LogoSvg from '../../../svg/Logo';
import LogoMultilineSvg from '../../../svg/LogoMultiline';

interface Props {
  multiline: boolean;
}

const VERSION = "v. 1.0.1";

const Logo = ({ multiline }: Props) => {
  const classes = useStyles();

  return (
    <div className={classes.logo}>
      {multiline ? (
        <Box className={classes.multilineWrapper}>
          <LogoMultilineSvg className={classes.multilineLogoSvg} />
          <span className={classes.label}>{VERSION}</span>
        </Box>
      ) : (
        <Box className={classes.wrapper}>
          <LogoSvg className={classes.logoSvg} />
          <span className={classes.label}>{VERSION}</span>
        </Box>
      )}
    </div>
  );
};

export default Logo;
